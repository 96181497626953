import {MenuCreateOptionInterface} from 'kernel/types/module'
class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      admin: [
        {
          label: this.vm.$t('page.accounting'),
          icon: 'fas fa-hand-holding-usd',
          create: (options: MenuCreateOptionInterface) => options.hasRole(['ROLE_ADMIN', 'ROLE_ACCOUNTING']),
          group: [
            {
              label: this.vm.$t('page.accounting-overview'),
              route: { name: 'accounting-overview' },
            },
            {
              label: this.vm.$t('page.accounting'),
              route: { name: 'accounting' },
            },
          ]
        },
      ],
    }
  }
}

export default () => (new menu()).get()
